import React, { useState } from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import PropTypes from 'prop-types'

import { formatMonetaryValue} from '../Formatter/monetaryValues';
import { getCondPgto, getDesc } from '../Formatter/clientes';

import { getFrete, getIpi } from '../Formatter/fornecedores';

import { getFormatData } from '../Formatter/pedidos';

import { getPromo } from '../Formatter/produtos';

const styles = StyleSheet.create({
  title: {
    fontSize: 12,
    textAlign: "center",
		fontFamily: "Times-Roman",
		fontWeight: "bolder",
  },

  tableFornecedores: {
    table: {
      paddingLeft: '10px',
      display: 'inlineBlock',
      width: '100%',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 2,
      paddingBottom: 2,
    },
    bold: {
      fontWeight: 'bold',
    },
    row1: {
      textAlign: 'left',
      fontSize: 8,
      width: '400px',
    },
    row2: {
      textAlign: 'left',
      fontSize: 8,
      width: '200px',
    },
    row3: {
      textAlign: 'left',
      fontSize: 8,
      width: '200px',
    },
    row4: {
      textAlign: 'left',
      fontSize: 8,
      width: '200px',
    },
    row5: {
      textAlign: 'left',
      fontSize: 8,
      width: '400px',
    },    
    row6: {
      textAlign: 'left',
      fontSize: 8,
      width: '200px',
    },

  },
  TableClientes: {
    table: {
      display: 'inlineBlock',
      width: '100%',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 2,
      paddingBottom: 2,
    },
    bold: {
      fontWeight: 'bold',
    },
    row1: {
      textAlign: 'left',
      fontSize: 8,
      width: '300px',
    },
    row2: {
      textAlign: 'left',
      fontSize: 8,
      width: '250px',
    },
    row3: {
      textAlign: 'left',
      fontSize: 8,
      width: '250px',
    },
    row4: {
      textAlign: 'left',
      fontSize: 8,
      width: '550px',
    },
    row5: {
      textAlign: 'left',
      fontSize: 8,
      width: '550px',
    },
  },
  tableItens: {
    table: {
      width: '100%',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 2,
      paddingBottom: 2,
    },
    header: {
      borderTop: 'none',
    },
    bold: {
      fontWeight: 'bold',
    },
    row1: {
      textAlign: 'center',
      fontSize: 7,
      width: '40px',
    },
    row2: {
      textAlign: 'center',
      fontSize: 7,
      width: '180px',
    },
    row3: {
      textAlign: 'center',
      fontSize: 7,
      width: '40px',
    },
    row4: {
      textAlign: 'center',
      fontSize: 7,
      width: '100px',
    },
    row5: {
      textAlign: 'center',
      fontSize: 7,
      width: '70px',
    },
    row6: {
      textAlign: 'center',
      fontSize: 7,
      width: '70px',
    },
    row7: {
      textAlign: 'center',
      fontSize: 7,
      width: '50px',
    },
    row8: {
      textAlign: 'center',
      fontSize: 7,
      width: '50px',
    },
    row9: {
      textAlign: 'left',
      fontSize: 7,
      width: '600px',
    },
    row10: {
      textAlign: 'left',
      fontSize: 7,
      width: '60px',
    },
    row11: {
      textAlign: 'left',
      fontSize: 7,
      width: '50px',
    },
    row12: {
      textAlign: 'left',
      fontSize: 7,
      width: '30px'
    },
    row13: {
      color: 'red',
      fontSize: 7,
    },
    row14: {
      textAlign: 'left',
      fontSize: 7,
      width: '180px',
    },
  },

})

export const TableFornecedores = ({ data, userGroup }) => {
  return (
    <>
      <View style={styles.tableFornecedores.table}>
        <View style={[styles.tableFornecedores.row]}>
          <Text style={styles.tableFornecedores.row1}>FORNECEDOR: {data.fornecedor.razao_social}</Text>
          <Text style={styles.tableFornecedores.row2}>
            {userGroup == 'Orcamento' || data.status == 0? (
            <>N. ORÇAMENTO:</>):(
            <>N. PEDIDO:</>
            )}{data.n_pedido}
          </Text> 
        </View>
        <View style={[styles.tableFornecedores.row]}>
          <Text style={styles.tableFornecedores.row1}>CNPJ: {data.fornecedor.cnpj}</Text>
          <Text style={styles.tableFornecedores.row4}>PRAZO ENTREGA: {data.prazo_entrega.prazo} DD</Text>
        </View>
        <View style={[styles.tableFornecedores.row]}>
          <Text style={styles.tableFornecedores.row5}>FRETE: {getFrete(data.frete)}</Text>
          <Text style={styles.tableFornecedores.row6}>DATA EMISSÃO: {getFormatData(data.data_pedido)}</Text>
        </View>
        
      </View>
    </>  
  )
}

export const TableClientes = ({ data }) => {
  return (
    <>
      <View style={styles.TableClientes.table}>
        <Text style={styles.title}>CLIENTE</Text>

        <View style={[styles.TableClientes.row]}>
          <Text style={styles.TableClientes.row1}>CLIENTE: {data.cliente.razao_social}</Text>
          <Text style={styles.TableClientes.row2}>CNPJ: {data.cliente.cnpj}</Text>
          <Text style={styles.TableClientes.row3}>OC CLIENTE: {data.oc_cliente}</Text>
        </View>
        <View style={[styles.TableClientes.row]}>
          <Text style={styles.TableClientes.row1}>FONE: {data.cliente.contato_principal.telefone}</Text>
          <Text style={styles.TableClientes.row2}>EMAIL: {data.cliente.contato_principal.email}</Text>
          <Text style={styles.TableClientes.row3}>COND. PGTO.: {getCondPgto(data.cond_pgto.desconto_comercial, data.cond_pgto.prazo)}</Text>
        </View>
        
        <View style={[styles.TableClientes.row]}>
          <Text style={styles.TableClientes.row4}>END. ENTREGA: {data.cliente.endereco_entrega.logradouro} {data.cliente.endereco_entrega.numero}</Text>
          <Text style={styles.TableClientes.row3}>COMPLEMENTO/BAIRRO: {data.cliente.endereco_entrega.complemento}/{data.cliente.endereco_entrega.bairro}</Text>
        </View>

        <View style={[styles.TableClientes.row]}>
          <Text style={styles.TableClientes.row1}>CIDADE: {data.cliente.endereco_entrega.municipio}</Text>
          <Text style={styles.TableClientes.row2}>ESTADO: {data.cliente.endereco_entrega.uf}</Text>
          <Text style={styles.TableClientes.row3}>CEP: {data.cliente.endereco_entrega.cep}</Text>
        </View>  
        <View style={[styles.TableClientes.row]}>
          <Text style={styles.TableClientes.row5}>OBS: {data.cliente.notas_para_pedido}</Text>
        </View>
      </View>
    </>
   
  )
}

export const TableItens = ({ data, modelo, valor, valorDesconto, valorIpi, valorFinalSeparado, tipo }) => {
  return (
    <>
      <View style={styles.tableItens.table}>
        <View style={[styles.tableItens.row, styles.tableItens.bold, styles.tableItens.header]}>
          <Text style={styles.tableItens.row1}>QTD</Text>
          <Text style={styles.tableItens.row2}>PRODUTO</Text>
          <Text style={styles.tableItens.row3}>P/C</Text>
          <Text style={styles.tableItens.row4}>OBS</Text>
          <Text style={styles.tableItens.row5}>REVESTIMENTO</Text>
          <Text style={styles.tableItens.row6}>ACABAMENTO</Text>
          <Text style={styles.tableItens.row7}>VLR. UNIT</Text>
          <Text style={styles.tableItens.row8}>TOTAL</Text>
        </View>
        {data.map((item, i) => (
          <View key={i} style={styles.tableItens.row} wrap={false}>
            <Text style={styles.tableItens.row1}>{item.quantidade}</Text>
            <Text style={styles.tableItens.row14}>{item.nome}</Text>
            <Text style={styles.tableItens.row3}>{getPromo(item)}</Text>
            <Text style={styles.tableItens.row4}>{item.obs}</Text>
            <Text style={styles.tableItens.row5}>{item.nomeRevestimento}</Text>
            <Text style={styles.tableItens.row6}>{(item.acabamento1+" "+item.acabamento2+" "+item.acabamento3)}</Text> 
            <Text style={styles.tableItens.row7}>{formatMonetaryValue(item.valor)}</Text>
            <Text style={styles.tableItens.row8}>{formatMonetaryValue(item.quantidade*item.valor)}</Text>
          </View>
        ))}
        <View style={[styles.tableItens.row, styles.tableItens.bold]}>
          <Text style={styles.tableItens.row9}>VALOR: </Text> 
          <Text style={styles.tableItens.row10}></Text> 
          <Text style={styles.tableItens.row11}>{formatMonetaryValue(valor)}</Text>
        </View>
        {tipo==="integral" ? (
					<View style={[styles.tableItens.row, styles.tableItens.bold]}>
            <Text style={styles.tableItens.row9}>DESCONTO COMERCIAL: </Text> 
            <Text style={styles.tableItens.row10}>{getDesc(modelo.cond_pgto.desconto_comercial)}</Text>
            <Text style={styles.tableItens.row11}>{formatMonetaryValue(valorDesconto)}</Text>
					</View>
        ):(
					<View style={[styles.tableItens.row, styles.tableItens.bold]}>
						<Text style={styles.tableItens.row9}>DESCONTO COMERCIAL: </Text> 
						<Text style={styles.tableItens.row10}>-</Text>
						<Text style={styles.tableItens.row11}>{formatMonetaryValue(valorDesconto)}</Text>
					</View>
				)}
        
        <View style={[styles.tableItens.row, styles.tableItens.bold]}>
          <Text style={styles.tableItens.row9}>IPI: </Text> 
          <Text style={styles.tableItens.row10}>{getIpi(modelo.ipi, modelo.percentual_ipi)}</Text>
          <Text style={styles.tableItens.row11}>{formatMonetaryValue(valorIpi)}</Text>
        </View>
        <View style={[styles.tableItens.row, styles.tableItens.bold]}>
          <Text style={styles.tableItens.row9}>VALOR FINAL: </Text> 
          <Text style={styles.tableItens.row10}></Text> 
          <Text style={styles.tableItens.row11}>{formatMonetaryValue(valorFinalSeparado)}</Text>
        </View>
        <View style={[styles.tableItens.row, styles.tableItens.bold]}>
          <Text style={styles.tableItens.row12}>NOTAS: </Text> 
          <Text style={styles.tableItens.row13}>{modelo.obs}</Text>
        </View>
      </View>
    </>
   
  )
}

TableItens.propTypes = {
  data: PropTypes.array.isRequired,
  modelo: PropTypes.array.isRequired,
}